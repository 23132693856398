
    import Vue from 'vue'
    import dict from '@custom/dict'
    import api from '@api/activity/apiMethod'

    const PortalHeader = () => import('../../component/common/PortalHeader.vue')
    const NewsBlock = () => import('../../component/common/NewsBlock.vue')
    const NCommonTitle = () => import('@corecp/NCommonTitle.vue')
    const PortalFooter = () => import('../../component/common/PortalFooter.vue')

    export default Vue.extend({
        name: 'ActivityList',
        components: {
            PortalHeader,
            NewsBlock,
            NCommonTitle,
            PortalFooter
        },
        data () {
            return {
                channelId: [],
                channelList: [],
                newsList: [
                ],
                activityList: [
                ],
                pagingInfo: {
                    onChange: (page: any) => {
                        console.log(page)
                    },
                    ...dict.commonObj.tablePagingInfoOpt
                }
            }
        },
        methods: {
            queryActivity () {
                const self = this as any
                api.queryEventList({
                    data: {
                        page: self.pagingInfo.current,
                        pageSize: self.pagingInfo.pageSize,
                        filterConds: []
                    },
                    success (res: any) {
                        res.data.forEach((item: any) => {
                            const htmlObj = document.createElement('div')
                            htmlObj.innerHTML = item.eventDescription
                            item.eventDescription = htmlObj.innerText
                        })
                        self.activityList = res.data
                        self.pagingInfo.pageSize = res.pageSize || 0
                        self.pagingInfo.current = res.curPage || 0
                        self.pagingInfo.total = res.totalSize || 0
                    },
                    error () {
                        self.$message.warn('请先登录')
                    }
                })
            },
            buildFilterConds (obj: any) {
                const filterConds = []
                for (const key of Object.keys(obj)) {
                    if (obj[key]) {
                        filterConds.push({
                            fieldName: key,
                            fieldValue: obj[key]
                        })
                    }
                }
                return filterConds
            },
            toArticleDetail (record: any) {
                const self = this as any
                window.open(`/portal/activitydetail?event_id=${record.eventId}`, '_blank')
            }
        },
        created () {
            const self = this as any
            self.channelId = self.$route.query.channel_id
            self.channelList = self.$route.query.channel_name ? self.$route.query.channel_name.split('_') : []
            self.queryActivity()
        }
    })
